import React from 'react'

import ProjectPostItem from '../components/project-post-item'

export const RelatedProjects = ({ data }) => {
  return data && data.edges && data.edges.length > 0 ? (
    <div className="bg-white">
      <div className="container mx-auto px-3 py-8">
        <h2>Our Recent Projects</h2>
        <div className="grid grid-cols-2 gap-4 lg:grid-cols-4">
          {data.edges.map((item, i) => (
            <ProjectPostItem
              page="projects"
              {...item.node}
              key={i}
              relatedProjectToBlog
            />
          ))}
        </div>
      </div>
    </div>
  ) : null
}
