import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const AreasServedContainer = styled.div`
  h1 {
    font-size: calc(0.75vw + 1.5em);
  }
`

export const AreasServed = (props) => {
  const {
    citiesList,
    className,
    serviceName = '',
    parentPageSlug,
    blogComponent,
  } = props

  const getServiceTitle = () => {
    if (serviceName.includes('New Home Construction')) return 'New Construction'
    if (serviceName.includes('Custom Homes')) return 'Custom Home Builder'
    if (serviceName.includes('Design Build Contractor'))
      return 'Design Build Contractor'
    if (serviceName.includes('Home Additions')) return 'Home Additions'

    if (serviceName.includes('Custom Home Builder'))
      return 'Custom Home Builder'
    if (serviceName.includes('Whole House Remodeling'))
      return 'Whole House Remodeling'
    if (serviceName.includes('ADU Builders')) return 'ADU Builders'
    return ''
  }

  const getServiceSlug = () => {
    console.log('serviceName', serviceName)
    if (serviceName.includes('New Home Construction')) return 'new-construction'
    if (serviceName.includes('Custom Home Builder'))
      return 'custom-home-builder'
    if (serviceName.includes('Custom Homes')) return 'custom-home-builder'
    if (serviceName.includes('Whole House Remodeling'))
      return 'whole-house-remodeling'
    if (serviceName.includes('ADU Builders')) return 'adu-builders'
    if (serviceName.includes('EV')) return 'custom-home-builder'
    if (serviceName.includes('Electrical')) return 'custom-home-builder'
    if (serviceName.includes('Home Additions')) return 'home-additions'

    return ''
  }

  const serviceTitle = getServiceTitle()
  const serviceSlug = getServiceSlug()

  return (
    <AreasServedContainer className={className}>
      <div className="container is-service-page">
        <div className="pt-8 pb-5">
          <h2>Areas Served</h2>
          {!blogComponent ? (
            <ul className="flex flex-wrap list-none m-0">
              {citiesList.map(
                (item, i) =>
                  (serviceName.includes('Siding') ||
                    (item.node.marketSegment &&
                      item.node.marketSegment[0] === 'sac')) && (
                    <li key={i} className="w-1/2 md:w-1/4 flex">
                      <div className="flex-shrink-0 pr-1">
                        <svg
                          className="h-6 w-6 text-green-500"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M5 13l4 4L19 7"
                          ></path>
                        </svg>
                      </div>{' '}
                      <Link
                        className="text-gray-800 hover:text-brand-600"
                        title={`${
                          item.node.location +
                          ' ' +
                          (serviceTitle ? serviceTitle + ' ' : '')
                        }`}
                        to={`/${serviceSlug ? serviceSlug : ''}-${
                          item.node.slug + '/'
                        }`}
                      >
                        {item.node.location}
                      </Link>
                    </li>
                  )
              )}
            </ul>
          ) : (
            <ul className="flex flex-wrap list-none m-0">
              {citiesList.map((item, i) => (
                <li key={i} className="w-1/2 md:w-1/4 flex">
                  <div className="flex-shrink-0 pr-1">
                    <svg
                      className="h-6 w-6 text-green-500"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                  </div>{' '}
                  <Link
                    className="text-gray-800 hover:text-brand-600"
                    title={`${
                      item.location + ' ' + (serviceTitle ? serviceTitle : '')
                    }`}
                    to={`/${serviceSlug ? serviceSlug : ''}-${item.slug + '/'}`}
                  >
                    {item.location}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </AreasServedContainer>
  )
}
